import { Link } from "gatsby"
import React from "react"

const ListLink = props => (
  <li
    style={{
      display: `inline-block`,
      marginRight: `1rem`,
      textDecoration: `none`,
    }}
  >
    <Link to={props.to}>{props.children}</Link>
  </li>
)

const Footer = () => (
  <footer
    style={{
      background: `#600`,
      marginTop: `1.45rem`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
      }}
    >
      <ul style={{ listStyle: `none`, float: `right` }}>
        <ListLink to="/tos">Terms of Service</ListLink>
        <ListLink to="/privacy">Privacy Policy</ListLink>
      </ul>
      Copyright © {new Date().getFullYear()}, Built with
      {` `}
      <a href="https://www.gatsbyjs.org">Gatsby</a>
    </div>
  </footer>
)

export default Footer
